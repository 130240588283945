import { useStyles, Button, MessageTooltip } from '@archipro-design/aria';
import { LinksLine, CheckLine } from '@archipro-design/icons';
import * as S from './DetailPageButtons.style';
import React from 'react';

interface CopyLinkButtonProps {
    url: string;
}

export const CopyLinkButton = ({ url }: CopyLinkButtonProps) => {
    const { css } = useStyles();

    const triggerRef = React.useRef<HTMLDivElement>(null);
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const [clicked, setClicked] = React.useState(false);

    const showTooltip = () => {
        setTooltipOpen(true);
    };

    const hideTooltip = () => {
        !clicked && setTooltipOpen(false);
    };

    return (
        <MessageTooltip
            open={tooltipOpen}
            trigger={
                <div
                    ref={triggerRef}
                    onMouseEnter={showTooltip}
                    onMouseLeave={hideTooltip}
                    onMouseDown={hideTooltip}
                >
                    <Button
                        className={css(S.ActionButton)}
                        icon={
                            clicked ? (
                                <CheckLine className={css(S.Copied)} />
                            ) : (
                                <LinksLine />
                            )
                        }
                        size={30}
                        color="white"
                        variables={{
                            iconPadding: 0,
                            buttonPadding: 16,
                            buttonRadius: `50%`,
                        }}
                        onClick={(event) => {
                            event.preventDefault();
                            navigator.clipboard.writeText(url);
                            setClicked(true);

                            setTimeout(() => {
                                setClicked(false);
                                setTooltipOpen(false);
                            }, 3000);
                        }}
                    />
                </div>
            }
            content={{
                children: clicked ? 'Copied' : 'Copy share link',
                className: css(S.TooltipContent),
                size: 18,
                padding: 12,
                color: 'white',
            }}
            position="after"
            offset={[1, 9]}
        />
    );
};
