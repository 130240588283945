import type { GetDirectoryPageMetaQuery } from 'generated/graphql';
import type { SupportedCountry } from '../../../../../localisation/src/types';
import type { AvailabilityZone } from '../api';
import type { MetaDescriptor } from '@remix-run/react';

/**
 * Add no-index to content that is from a different country than the current domain.
 * Only if the page has one availability zone
 * @param itemCountry
 * @param currentCountry
 * @param availabilityZones
 * @returns
 */
export const noIndexCrossCountry = (
    itemCountry?: string,
    currentCountry?: SupportedCountry,
    localisation?: GetDirectoryPageMetaQuery['getDirectoryPageMeta']
): MetaDescriptor[] => {
    if (!itemCountry || !currentCountry || !localisation) {
        return [];
    }
    const availableCountries =
        localisation?.AvailabilityZones?.map((A: AvailabilityZone | null) => {
            return A?.Country.Code;
        }) ?? [];

    if (itemCountry !== currentCountry && availableCountries.length === 1) {
        return [{ name: 'robots', content: 'noindex' }];
    } else {
        return [];
    }
};
