import { useStyles, Button, MessageTooltip } from '@archipro-design/aria';
import { PencilLine } from '@archipro-design/icons';
import * as S from './DetailPageButtons.style';
import React from 'react';

interface EditModeToggleProps {
    editLink: string;
}

export const EditModeToggle = ({ editLink }: EditModeToggleProps) => {
    const { css } = useStyles();

    const triggerRef = React.useRef<HTMLDivElement>(null);
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const showTooltip = () => {
        setTooltipOpen(true);
    };

    const hideTooltip = () => {
        setTooltipOpen(false);
    };

    const onClick = () => {
        sessionStorage.setItem(
            'edit_mode',
            JSON.stringify({
                value: editLink,
                expiry: new Date().getTime(),
            })
        );
        window.location.href = editLink;
    };

    return (
        <MessageTooltip
            open={tooltipOpen}
            trigger={
                <div
                    ref={triggerRef}
                    onMouseEnter={showTooltip}
                    onMouseLeave={hideTooltip}
                    onMouseDown={hideTooltip}
                >
                    <Button
                        id={'BMEditModeToggle'}
                        className={css(S.ActionButton)}
                        as="a"
                        onClick={onClick}
                        icon={<PencilLine />}
                        size={30}
                        color="white"
                        variables={{
                            iconPadding: 0,
                            buttonPadding: 16,
                            buttonRadius: `50%`,
                        }}
                    />
                </div>
            }
            content={{
                children: 'Switch to edit mode',
                className: css(S.TooltipContent),
                size: 18,
                padding: 12,
                color: 'white',
            }}
            position="after"
            offset={[1, 9]}
        />
    );
};
