import { useStyles, useAppDisplayModeContext } from '@archipro-design/aria';
import * as S from './DetailPageButtons.style';
import { useHydrated } from 'remix-utils';
import { EditModeToggle } from './EditModeToggle';
import { CopyLinkButton } from './CopyLinkButton';
import makeSharableInternalLink from '../../util/shareable-internal-link';

export interface DetailPageButtonsProps {
    professionalId: number;
    editLink: string;
    shareableLink: string;
    id: number;
    canEdit: boolean;
    canCopyLink: boolean;
    showButton?: boolean;
}

export const DetailPageButtons = ({
    editLink,
    shareableLink,
    id,
    canEdit,
    canCopyLink,
    showButton = true,
}: DetailPageButtonsProps) => {
    const { css } = useStyles({ showButton });
    const isHydrated = useHydrated();

    const { desktop } = useAppDisplayModeContext();
    if (!desktop) return null;

    const internalLink = isHydrated
        ? makeSharableInternalLink(window.location.origin, shareableLink, id)
        : '';

    return (
        <div className={css(S.ButtonsContainer)}>
            {canCopyLink && <CopyLinkButton url={internalLink} />}
            {canEdit && <EditModeToggle editLink={editLink} />}
        </div>
    );
};
