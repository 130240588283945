import { Caption, Flex, useStyles } from '@archipro-design/aria';

import { ErrorWarningFill } from '@archipro-design/icons';

import * as S from './WarningBanner.style';

interface WarningBannerProps {
    alerts?: string[];
}

const WarningBanner = ({ alerts }: WarningBannerProps) => {
    const { css } = useStyles();

    if (!alerts || alerts.length === 0) return null;

    return (
        <div className={`${css(S.WarningBannerContainer)} productBanner`}>
            <Flex vAlign="start" hAlign="center">
                <ErrorWarningFill />
                <Caption
                    variant="03"
                    className={css(S.WarningBannerContent)}
                    variables={{
                        lineHeight: '150%',
                    }}
                >
                    {alerts.map((alert) => (
                        <span key={alert}>{alert}</span>
                    ))}
                </Caption>
            </Flex>
        </div>
    );
};

export default WarningBanner;
