import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, messageTooltipContentClassName } from '@archipro-design/aria';
import { hex2rgba } from '~/utils/colorHelper';

export const ButtonsContainer: StyleRule = ({ theme, showButton }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: pxToRem(16),
    position: 'fixed',
    left: pxToRem(32),
    bottom: pxToRem(24),
    zIndex: theme.siteVariables.zIndexes.overlayPriority,
    transition: 'visibility 300ms, opacity 300ms',
    opacity: showButton ? 1 : 0,
    visibility: showButton ? 'visible' : 'hidden',
});

export const ActionButton: StyleRule = ({ theme }) => ({
    boxShadow: `0px 14px 24px 2px ${hex2rgba(
        theme.siteVariables.colors.primitive.black,
        0.08
    )}`,
});

export const TooltipContent: StyleRule = ({ theme }) => ({
    zIndex: theme.siteVariables.zIndexes.foreground,
    [`&.${messageTooltipContentClassName}`]: {
        maxWidth: 'unset',
        textAlign: 'center',
    },
});

export const Copied: StyleRule = ({ theme }) => ({
    fill: theme.siteVariables.colors.success['200'],
});
