import { useAppDisplayModeContext } from '@archipro-design/aria';
import { useKeyPress } from 'ahooks';
import { useCanEdit } from './use-can-edit';

const isIgnoredElement = (element: EventTarget | null) => {
    const ignoredElementTypes = [
        HTMLInputElement,
        HTMLTextAreaElement,
        HTMLSelectElement,
    ];
    return ignoredElementTypes.some((type) => element instanceof type);
};

export const useSetEditMode = (editPath: string, professionalId?: number) => {
    const { mobile } = useAppDisplayModeContext();
    const canEdit = useCanEdit(professionalId);

    useKeyPress(['e'], (event) => {
        if (isIgnoredElement(event.target)) return;

        if (mobile) return;

        if (canEdit) {
            // break out of SPA to go the edit page
            window.location.href = editPath;
        }
    });
};
