import { svgIconClassName } from '@archipro-design/icons';

import type { StyleRule } from '@archipro-design/aria';

import { pxToRem, flexClassName, pxArrayToRem } from '@archipro-design/aria';

export const WarningBannerContainer: StyleRule = ({ theme }) => ({
    width: '100%',
    minHeight: pxToRem(56),
    zIndex: theme.siteVariables.zIndexes.topNav,
    '& *': {
        color: theme.siteVariables.colors.primitive.white,
    },
    background: '#FAF9F7', // Not avail in sitevars

    [`& .${flexClassName}`]: {
        width: '100%',
        height: '100%',
        justifyContent: 'flex-start',
        padding: pxArrayToRem([16, 16]),
        background: theme.siteVariables.colors.danger[200],

        [theme.screen.tablet]: {
            width: `calc(100% - ${pxToRem(100)})`,
            margin: '0 auto',
            padding: pxArrayToRem([16, 0]),
            justifyContent: 'center',
        },
    },

    [`& .${svgIconClassName}`]: {
        width: pxToRem(24),
        height: pxToRem(24),
        marginRight: pxToRem(8),
        '& svg': {
            fill: theme.siteVariables.colors.primitive.white,
        },
    },
});

export const WarningBannerContent: StyleRule = () => ({
    [`& span`]: {
        display: 'block',
    },
});
